import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0225d88a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardGrid = _resolveComponent("DashboardGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DashboardGrid, {
      url: '/form/grid/DashboardRedosCountPartial',
      additionalData: _ctx.dashboardRedoTypeGeneral,
      dataFunctionName: 'dashboardRedoTypeGeneral',
      exportUrl: 'form/grid/ExportRedoOrdersCsv',
      exportName: 'RedoOrders'
    }, null, 8, ["additionalData"]),
    _createVNode(_component_DashboardGrid, {
      url: '/form/grid/DashboardRedoOrdersPercentageOrdersTabPartial',
      exportUrl: 'form/grid/ExportRedoOrdersCsv',
      exportName: 'RedoOrders'
    }),
    _createVNode(_component_DashboardGrid, {
      url: '/form/grid/DashboardProductionIssueRedosCountPartial',
      additionalData: _ctx.dashboardRedoTypeProduction,
      dataFunctionName: 'dashboardRedoTypeProduction',
      exportUrl: 'form/grid/ExportRedoOrdersCsv',
      exportName: 'RedoOrders'
    }, null, 8, ["additionalData"]),
    _createVNode(_component_DashboardGrid, {
      url: '/form/grid/DashboardProductionIssueRedoOrdersPercentagePartial',
      additionalData: _ctx.orderRedoReasonTypeProduction,
      dataFunctionName: 'orderRedoReasonTypeProduction',
      exportUrl: 'form/grid/ExportRedoOrdersCsv',
      exportName: 'RedoOrders'
    }, null, 8, ["additionalData"]),
    _createVNode(_component_DashboardGrid, {
      url: '/form/grid/DashboardDeliveryIssueRedosCountPartial',
      additionalData: _ctx.orderRedoReasonTypeProduction,
      dataFunctionName: 'dashboardRedoTypeDelivery',
      exportUrl: 'form/grid/ExportRedoOrdersCsv',
      exportName: 'RedoOrders'
    }, null, 8, ["additionalData"]),
    _createVNode(_component_DashboardGrid, {
      url: '/form/grid/DashboardDeliveryIssueRedoOrdersPercentagePartial',
      additionalData: _ctx.orderRedoReasonTypeDelivery,
      dataFunctionName: 'orderRedoReasonTypeDelivery',
      exportUrl: 'form/grid/ExportRedoOrdersCsv',
      exportName: 'RedoOrders'
    }, null, 8, ["additionalData"]),
    _createVNode(_component_DashboardGrid, {
      url: '/form/grid/DashboardRedosValuePartial',
      additionalData: _ctx.dashboardRedoTypeGeneral,
      dataFunctionName: 'dashboardRedoTypeGeneral',
      exportUrl: 'form/grid/ExportRedoOrdersCsv',
      exportName: 'RedoOrders'
    }, null, 8, ["additionalData"]),
    _createVNode(_component_DashboardGrid, {
      url: '/form/grid/DashboardProductionIssueRedosValuePartial',
      additionalData: _ctx.dashboardRedoTypeProduction,
      dataFunctionName: 'dashboardRedoTypeProduction',
      exportUrl: 'form/grid/ExportRedoOrdersCsv',
      exportName: 'RedoOrders'
    }, null, 8, ["additionalData"]),
    _createVNode(_component_DashboardGrid, {
      url: '/form/grid/DashboardDeliveryIssueRedosValuePartial',
      additionalData: _ctx.dashboardRedoTypeDelivery,
      dataFunctionName: 'dashboardRedoTypeDelivery',
      exportUrl: 'form/grid/ExportRedoOrdersCsv',
      exportName: 'RedoOrders'
    }, null, 8, ["additionalData"])
  ]))
}