
import { defineComponent } from 'vue';
import DashboardGrid from '@/components/DashboardGrid.vue';

export default defineComponent({
  name: 'RedoOrdersTab',
  components: {
    DashboardGrid,
  },
  data() {
    return {
      dashboardRedoTypeDelivery: {
        dashboardRedoType: 0,
      },

      dashboardRedoTypeProduction: {
        dashboardRedoType: 1,
      },

      dashboardRedoTypeGeneral: {
        dashboardRedoType: 2,
      },

      orderRedoReasonTypeDelivery: {
        orderRedoReasonType: 1,
      },

      orderRedoReasonTypeProduction: {
        orderRedoReasonType: 2,
      }
    }
  }
});
